<template>
  <section class="stock-provide-wrapper">
    <b-row class="provide-add">
      <b-col cols="12">
        <b-card no-body class="stock-preview-card">
          <!-- Header -->
          <b-card-body class="stock-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column stock-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <h3 class="text-primary stock-logo">{{ stockData ? stockData.name : '-----' }}</h3>
                </div>
              </div>

              <!-- Header: Right Content -->
              <div>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    @click="repeateAgain"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>Nouvelle ligne</span>
                </b-button>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="stock-spacing" />

          <!--          Form-->
          <b-form
            ref="form"
            :style="{ height: trHeight }"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >
            <b-card-body class="stock-padding form-item-section">
              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in items"
                :id="index"
                :key="index"
                ref="row"
              >
                <!-- Product Name -->
                <b-col md="4">
                  <label class="d-inline d-lg-none">Produit</label>
                  <v-select
                      v-model="item.product"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="availableProducts"
                      label="name"
                      :clearable="false"
                      class="mb-2 item-selector-title"
                      placeholder="Sélectionner un produit"
                      @input="(val) => updateItemForm(index, val)"
                  />
                  <b-form-group label="Produit" label-for="product-name">
                    <b-form-input
                      id="product-name"
                      type="text"
                      placeholder="Sélectionner produit"
                    />
                  </b-form-group>
                </b-col>

                <!-- Product State -->
                <b-col md="2">
                  <b-form-group label="État" label-for="state">
                    <b-form-input id="state" placeholder="État" />
                  </b-form-group>
                </b-col>

                <!-- Quantity -->
                <b-col md="2">
                  <b-form-group label="Quantité" label-for="quantity">
                    <b-form-input id="quantity" type="number" placeholder="1" />
                  </b-form-group>
                </b-col>

                <!-- Available -->
                <b-col lg="2" md="1">
                  <b-form-group label="Disponible" label-for="available">
                    <b-form-input id="available" value="32" plaintext />
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col lg="2" md="3" class="mb-50">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Supprimer</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
            </b-card-body>

            <b-card-footer>
              <div class="d-flex flex-row justify-content-between mb-75">
                <div>
                  <b-button variant="warning" size="sm">
                    <feather-icon icon="CornerUpLeftIcon" />
                    Retour
                  </b-button>
                </div>
                <div>
                  <b-button
                    variant="danger"
                    size="sm"
                    type="reset"
                    :disabled="true"
                  >
                    <feather-icon icon="XCircleIcon" />
                    Effacer
                  </b-button>
                  <b-button
                    variant="success"
                    class="ml-3"
                    size="sm"
                    type="submit"
                    :disabled="true"
                  >
                    <feather-icon icon="SaveIcon" />
                    <span> Sauvegarder</span>
                  </b-button>
                </div>
              </div>
            </b-card-footer>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BCardBody,
  BCardFooter,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardFooter,
    BCard,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isLoading: false,
      items: [
        {
          product: "",
          state: "",
          quantity: 0,
        },
      ],
      nextTodoId: 2,
    };
  },
  computed: {
    stockData() {
      return this.$store.getters["stock/stock"];
    },
    // products list
    availableProducts() {
      return this.$store.getters["item/all"];
    }
  },
  mounted() {
    this.initTrHeight();

  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    async getStock() {
      try {
        await this.$store.dispatch("stock/fetchStock", {
          store_id: this.$route.params.storeID,
          data: {
            id: this.$route.params.id,
          },
        });
      } catch (e) {

      }
    }
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
